import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
    ADVANTAGES,
    BUSINESS_BENEFITS,
    COMPANY_LOGOS,
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS_LIST,
    SECTIONS_ID,
    SECTIONS_TRANSLATION,
} from 'views/react-js/constants';
import { CONSTANTS } from 'constants/styles/constants';
import { FooterSection } from 'modules/footer-section';
import { ObjectiveAdvantagesSection } from 'modules/objective-advantages-section';
import { BookConsultationSection } from 'modules/technologies/book-consultation-section';
import { BusinessBenefitsSection } from 'modules/technologies/business-benefits-section';
import { AnimatedBusinessBenefits } from 'modules/technologies/business-benefits-section/components/animated-business-benefits';
import { CodeConsultingSection } from 'modules/technologies/code-consulting-section';
import { HeaderSection } from 'modules/technologies/header-section';
import { AnimatedDevelopment } from 'modules/technologies/header-section/components/animated-native-development';
import { HireDevelopersSection } from 'modules/technologies/hire-developers-section';
import { RealResultsSection } from 'modules/technologies/real-results-section';
import { SectionsNavigationBar } from 'modules/technologies/sections-navigation-bar';
import { WorkWithMRSection } from 'modules/technologies/work-with-mr-section';
import { array } from 'prop-types';
import ReactJsSvg from 'svgs/technologies/react-js/react-js-development.svg';
import BusinessBenefits from 'svgs/technologies/react-native/business-benefits.svg';

const Container = styled.div`
    width: 100%;
    padding-top: ${CONSTANTS.MARGIN.PAGE};
`;

const Props = {
    faqData: array.isRequired,
};

export const ReactJsView = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <HeaderSection
                title="react-js.header.title"
                descriptions={['react-js.header.description']}
                img={
                    <AnimatedDevelopment>
                        <ReactJsSvg />
                    </AnimatedDevelopment>
                }
                alt="react-js.header.imgAlt"
            />
            <SectionsNavigationBar
                data={SECTIONS_TRANSLATION}
                ids={SECTIONS_ID}
            />
            <CodeConsultingSection
                id={SECTIONS_ID[0]}
                title="react-js.topics.codeConsulting"
                subTitle="react-js.codeConsulting.subTitle"
                descriptions={[
                    'react-js.codeConsulting.descriptions.0',
                    'react-js.codeConsulting.descriptions.1',
                    'react-js.codeConsulting.descriptions.2',
                ]}
            />
            <BookConsultationSection />
            <RealResultsSection
                id={SECTIONS_ID[1]}
                title="react-js.famousApps.title"
                logos={COMPANY_LOGOS}
                margin
            />
            <ObjectiveAdvantagesSection
                id={SECTIONS_ID[2]}
                data={ADVANTAGES}
                title="react-js.topics.objectiveAdvantages"
                subTitle="react-js.objectiveAdvantages.subTitle"
                rootPath="react-js.objectiveAdvantages.reasons"
            />
            <HireDevelopersSection
                id={SECTIONS_ID[3]}
                title="react-js.topics.hireDevelopers"
                description="react-js.ourDevelopers"
            />
            <BusinessBenefitsSection
                id={SECTIONS_ID[4]}
                logo={
                    <AnimatedBusinessBenefits>
                        <BusinessBenefits />
                    </AnimatedBusinessBenefits>
                }
                data={BUSINESS_BENEFITS}
                title="react-js.topics.businessBenefits"
                description="react-js.businessBenefits.description"
            />
            <WorkWithMRSection
                id={SECTIONS_ID[5]}
                title="react-js.workWithMobileReality.title"
                firstRowDescriptions={[
                    'react-js.workWithMobileReality.atMobileReality',
                    'react-js.workWithMobileReality.agileDevelopment',
                ]}
                secondRowDescriptions={[
                    'react-js.workWithMobileReality.collaborativeApproach',
                ]}
                perksData={PERKS_LIST}
                firstRowAlt={formatMessage({
                    id: 'react-js.workWithMobileReality.firstRowAlt',
                })}
                secondRowAlt={formatMessage({
                    id: 'react-js.workWithMobileReality.secondRowAlt',
                })}
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </Container>
    );
};

ReactJsView.propTypes = Props;
